import 'core-js/features/string/pad-start';

export function randomHex8(): string {
  return Math.floor(Math.random() * 0x100000000)
    .toString(16)
    .padStart(8, '0');
}

export function randomHex4(): string {
  return Math.floor(Math.random() * 0x10000)
    .toString(16)
    .padStart(4, '0');
}

export function randomHex3(): string {
  return Math.floor(Math.random() * 0x1000)
    .toString(16)
    .padStart(3, '0');
}

export function randomHex2(): string {
  return Math.floor(Math.random() * 0x10).toString(16);
}

export function randomHex1(): string {
  return Math.floor(Math.random() * 0x10).toString(16);
}

/**
 * UUID v4 を生成する
 * STB では uuid が使えないので自前で実装する
 *
 */
export function randomUUID(): string {
  // RRRRRRRR-RRRR-4RRR-rRRR-RRRRRRRRRRRR
  // dbb07710-9619-467e-b4d3-6e3c43617ce5

  return `${randomHex8()}-${randomHex4()}-4${randomHex3()}-b${randomHex3()}-${randomHex8()}${randomHex4()}`;
}
