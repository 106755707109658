import { randomUUID } from '#/utils/uuid';

export interface Page {
  show(): void;
  hide(): void;
  unmount(): void;
  unmounted?(): Promise<void>;

  onKeydown?(e: KeyboardEvent): void;
  onKeyup?(e: KeyboardEvent): void;
  onKeypress?(e: KeyboardEvent): void;
}

type PageId = string;

let _history: [PageId, Page][] = [];

export function openPage(page: Page): void {
  const id: PageId = randomUUID();

  if (_history.length) {
    const [, top] = _history[_history.length - 1];
    top.hide();
  }

  pushState(id);
  page.show();
  _history.push([id, page]);

  if (page.unmounted) {
    page.unmounted().then(() => {
      // 挿入済みの Page が自発的にクローズした場合
      const [topId, top] = _history[_history.length - 1];

      if (topId === id) {
        // 最上位の Page が自発的にクローズした場合
        // top を非表示にしてひとつ前の Page を表示する
        top.hide();
        _history.pop();

        if (_history.length) {
          const [, prev] = _history[_history.length - 1];
          prev.show();
        }
      } else {
        // それ以外の Page が自発的にクローズした場合
        // _history から自身を削除
        _history = _history.filter(([i]) => i !== id);
      }
    });
  }
}

export function moveBackPage(): void {
  if (_history.length <= 1) {
    return;
  }

  const [, top] = _history.pop()!;

  top.hide();
  top.unmount();

  const [, prev] = _history[_history.length - 1];
  prev.show();
}

window.addEventListener('keydown', (e) => {
  if (e.which === VK_BACK) {
    moveBackPage();
    return;
  }

  if (_history.length) {
    const [, top] = _history[_history.length - 1];

    if (top.onKeydown) {
      top.onKeydown(e);
    }
  }
});

window.addEventListener('keyup', (e) => {
  if (e.which === VK_BACK) {
    return;
  }

  if (_history.length) {
    const [, top] = _history[_history.length - 1];

    if (top.onKeyup) {
      top.onKeyup(e);
    }
  }
});

window.addEventListener('keypress', (e) => {
  if (e.which === VK_BACK) {
    return;
  }

  if (_history.length) {
    const [, top] = _history[_history.length - 1];

    if (top.onKeypress) {
      top.onKeypress(e);
    }
  }
});

// =============================================================================
//
// PC 用ページ内ルーティング
//
// =============================================================================
const useRouteing: boolean = !!(window.history && window.history.pushState);
if (useRouteing) {
  console.log('useRouteing');
}

function pushState(id: PageId): void {
  if (useRouteing) {
    window.history.pushState({}, '', `#${id}`);
  }
}

if (useRouteing) {
  window.addEventListener('popstate', (e) => {
    console.log('popstate', e);
    // console.log('history', window.location.hash);
    moveBackPage();

    console.log('hash', window.location.hash, _history[_history.length - 1][0]);
    // window.history.back();
    window.history.pushState({}, '', window.location.hash);
    // console.log('history', _history[_history.length - 1][0]);
  });
}
